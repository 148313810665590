
// Packages
import { defineComponent } from 'vue';
import { mapState } from 'pinia';

// Config
import { GTM_URLS_BY_ID } from '@white-label-configuration/constants';

// Helpers
import { getAppVariable } from '@white-label-helper/get-app-variable';

// Stores
import { usePreloaderControllerStore } from '@white-label-store/preloader-controller';

// Types
import type { MetaInfo } from 'vue-meta';

// Mixins
import bookingPortal from '@white-label-helper/mixin-booking-portal';
import { EcomFooter, ModalContainer, FullScreenPreloader, CheckoutNavbar } from 'feature-shared-organisms';

export default defineComponent({
  components: {
    AppFooter: EcomFooter,
    CheckoutNavbar,
    FullscreenPreloader: FullScreenPreloader,
    ModalContainer,
  },

  mixins: [bookingPortal],

  head(): MetaInfo {
    const routeName = this.$route.name;
    const pageName = this.$t(`pages.titles.${routeName}`);
    const title = `${pageName} | ${getAppVariable('partner_name')}`;

    return {
      title,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      script: [
        {
          json: this.$partnerConfig as { [key: string]: any },
          type: 'application/json',
          vmid: 'partner-channel-config',
        },
      ]
    };
  },

  computed: {
    ...mapState(usePreloaderControllerStore, {
      message: 'getMessage',
    }),
  },

  mounted() {
    const gtmId = this.$partnerConfig ? this.$partnerConfig.gtm_id as string : process.env.NUXT_ENV_GTM_ID as string;
    const scriptURL = GTM_URLS_BY_ID[gtmId];
    this.$bootstrapGTM({ id: gtmId, scriptURL });
  },
});
